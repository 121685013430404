html, body {
  margin: 0;
  padding: 0;
  font-size: 100%;
}

.App {
  text-align: center;
}

.background {
  background-image: url('./images/space-backing.png');
  height: auto;
}

.border-bottom {
  border-bottom: 1px;
  border-bottom-color: white;
  border-bottom-style: solid;
}

.button-floating {
  background-color: white !important;
}

.card {
  border-color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
}

.card-action {
  height: 65px;
}

.card-container {
 width: 65%;
 padding: 100px 0;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-content {
  height: 160px;
  text-align: left;
}

.card-title {
  padding: 0 0 0 20px !important;
}

.footer {
  border-top: 1px;
  border-top-color: white;
  border-top-style: solid;
}

.header-border {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: white;
}

.header-button-initial,
.header-button-subsequent {
  display: inline-block;
  padding: 20px;
}

.header-button-subsequent {
  padding: 20px 20px 20px 0px;
}

.header-text {
  display: inline-block;
  padding-bottom: 10px;
}

.highlight {
  background-color: white;
  color: black;
  padding: 0px 5px 0px 5px;
}

.highlight-inverse {
  background-color: black;
  color: white;
  padding: 0px 5px 0px 5px;
}

hr {
  border-top: 3px solid black;
  border-radius:  5px;
}

@keyframes aboutMeBackground {
  0%   {background-color: white;}
  50%  {background-color: black;}
  100% {background-color: white;}
}

.about-me-background {
  animation-name: aboutMeBackground;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes aboutMeIcon {
  0%   {color: black;}
  50%  {color: white;}
  100% {color: black;}
}

.about-me-icon {
  animation-name: aboutMeIcon;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

.row {
  margin-bottom: 0px;
}

.star-destroyer-modal {
  width: 80%;
}

.tag {
  display: inline-block;
  margin: 5px 5px 0px 0px;
}

ul {
  margin: 0;
}

.video-responsive {
  height: 420px;
}

@media only screen and (min-width: 0px) and (max-width: 426px) {

  .card {
    border-color: white;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
  }  
  
  .card-container {
    width: 80%;
  }

  h3 {
    font-size: 20pt;
  }

  .header-button-initial,
  .header-button-subsequent {
    display: inline-block;
    padding: 10px;
  }

  .header-button-subsequent {
    padding: 10px 10px 10px 0px;
  }

}

@media only screen and (min-width: 426px) and (max-width: 768px) {

  .background {
    background-image: url('./images/space-backing.png');
    height: auto;
  }

  .card-container {
    width: 80%;
  }

  .header-button-initial,
  .header-button-subsequent {
    display: inline-block;
    padding: 10px;
  }

  .header-button-subsequent {
    padding: 10px 10px 10px 0px;
  }

  h3 {
    font-size: 20pt;
  }

}
